.pending_table {
  .pending_button {
    border-radius: 5px;
    background: #f9712433;
    font-family: Lato, Sans-serif;
    padding: 6px 7px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    color: #f97124;
    border-color: transparent;

    &:hover {
      background: #f9712433;
      color: #f97124;
    }
  }

  .Decline_button {
    border-radius: 6.25px;
    color: #f93440;
    background: transparent;
    border: 1px dashed #f93440;
    font-family: Lato, Sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 6px 24px;

    &:hover {
      color: #f93440;
      background: transparent;
      border-color: #f93440;
    }
  }

  .Accept_button {
    border-radius: 6.25px;
    color: #1e1f23;
    background: #0aceec;
    font-family: Lato, Sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 0;
    margin-left: 7px;
    padding: 6px 15px;

    &:hover {
      color: #1e1f23;
      background: #0aceec;
    }
  }

  .Edit_button {
    background: transparent;
    border: 0;
    padding: 0;

    &:hover {
      background: transparent;
    }
  }
}

.decline_modal {
  .modal-header {
    border: 0;

    .btn-close {
      color: #fff;
      background-image: url(../../../assests/images/dashborad/close.png);
      background-size: 100%;
      opacity: 1;
      box-shadow: none;
    }

    .h4 {
      font-family: Lato, Sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: #fff;
    }


  }

  .modal-body {
    .export-button {
      border-radius: 10px;
      border: 1px solid #0aceec;
      height: 42px;
      width: 100%;
      color: #0aceec;

      &:hover {
        background-color: #0aceec;
        color: #1e1f25;
      }
    }

    .export-text{
      font-family: Lato, Sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: #fff;
    }


  }



  .modal-content {
    background: #282c38;

    // p {
    //   font-family: Lato, Sans-serif;
    //   font-size: 13px;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: normal;
    //   color: #b2b9cd;
    //   margin: 0;
    //   padding-bottom: 7px;
    // }

    textarea {
      border: 1px solid #3c4254;
      background: #1e1f25;
      color: #fff;
      font-family: Lato, Sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 20px;
    }

    textarea::placeholder {
      color: #fff;
      font-family: Lato, Sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    select {
      border: 1px solid #3c4254;
      background: #1e1f25;
      font-family: Lato, Sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #fff;
      height: 47px;
      width: 100%;
      border-radius: 8px;
      padding: 13px;
      margin-bottom: 23px;
    }
  }

  .modal-footer {
    border: 0;
    justify-content: center;

    .cancel_button {
      border: 1px solid #3c4254;
      background: transparent;
      font-family: Lato, Sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: #0aceec;
      width: 96%;
      border-radius: 18px;
    }

    .confirm_button {
      border-radius: 18px;
      border: 1px solid #0aceec;
      background: #0aceec;
      font-family: Lato, Sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: #1e1f25;
      width: 96%;
    }
  }
}