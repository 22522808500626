.is-invalid-label {
  border-color: red !important;
  text-align: left;
}

.password-input-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-criteria {
  margin-top: 10px;
  .criteria-valid {
    color: green;
  }
  .criteria-invalid {
    color: red;
  }
}
.not-allowed-cursor {
  cursor: not-allowed;
}

// .password-input-wrapper {
//   display: flex;
//   align-items: center;
// }

// .password-toggle-icon {
//   margin-left: 10px;
//   cursor: pointer;
// }
