.password-criteria {
    margin-top: 20px;
    color: #ffffff;  // assuming you want white text as per the image
    font-size: 14px;
  
    div {
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    ol {
      padding-left: 20px;
  
      li {
        margin-bottom: 5px;
      }
    }
  }