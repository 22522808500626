.dashboard_page {
  padding-top: 17px;
  .inner_div {
    border-radius: 10px;
    background: #1e1f25;
    padding: 22px;
    display: flex;
    margin-bottom: 15px;

    p {
      margin: 0;

      &:first-child {
        color: #b2b9cd;
        font-family: Lato, Sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &:last-child {
        color: #fff;
        font-family: Lato, Sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
