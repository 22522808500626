.main_wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .side-wrapper {
    max-width: 249px;
  }
  .content-wrapper {
    width: calc(100vw - 300px);
  }
  .content-wrapper_collpase {
    width: calc(100vw - 160px);
  }
  .Scroll {
    height: calc(100vh - 277px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.patientDetailScroll {
  height: calc(100vh - 197px);
  overflow-y: auto;
  overflow-x: hidden;
}
.patientDetailScroll::-webkit-scrollbar-track,
.Scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255) !important;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}
.patientDetailScroll::-webkit-scrollbar,
.Scroll::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f5f5f5 !important;
}
.patientDetailScroll::-webkit-scrollbar-thumb,
.Scroll::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.72, #0aceec),
    color-stop(0.72, #0aceec),
    color-stop(0.72, #0aceec)
  ) !important;
}
