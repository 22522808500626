.table-responsive {
  border-radius: 20px;

  .Patients-table {
    margin: 0px !important;

    .input-group {
      margin: 0 !important;
      position: inherit;

      .input-group-text {
        padding: 0 !important;
        border: 0 !important;

        .form-check-input {
          margin: 0 !important;
          height: 20px;
          width: 20px;
          background-color: #282c38;
          border-color: #3c4254;

          &:focus {
            box-shadow: none;
          }
        }

        .form-check-input:checked {
          background-color: #246cf9;
          border-color: #3c4254;
        }
      }
    }

    th {
      position: sticky; /* make the table heads sticky */
      top: 0px;
      font-family: Inter, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #ffffff !important;
      height: 50px !important;
      //line-height: 50px !important;
      //padding: 0 !important;
      margin: 0 !important;
      font-family: Lato, Sans-serif;
      vertical-align: middle;
      border-color: #282c38;

      &:first-child {
        padding-left: 27px;
      }
    }

    td {
      font-family: Inter, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 50px !important;
      //line-height: 50px !important;
      //padding: 0 !important;
      font-family: Lato, Sans-serif;
      margin: 0 !important;
      vertical-align: middle;
      color: #b2b9cd;
      border-color: #282c38;

      &:first-child {
        padding-left: 27px;
      }
    }

    .name-text {
      color: #0aceec;
      text-decoration: underline;
      cursor: pointer;
    }
    .name-admin-user{
      color: #0aceec;
    }

    .form-check-input {
      width: 36px;
      height: 20px;
      background-color: #282c38;
      border-color: #3c4254;

      &:checked {
        background-color: #0aceec;
        border-color: #0aceec;
        cursor: pointer;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
