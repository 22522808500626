.payment_page {
  p {
    color: #b2b9cd;
    font-family: Lato, Sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
  }

  h3 {
    color: #0aceec;
    font-family: Lato, Sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    span {
      color: #30e0a1;
      font-family: Lato, Sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      padding-left: 16px;
    }
  }

  .plan_details {
    padding-top: 50px;
    h4 {
      color: #b2b9cd;
      font-family: Lato, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        position: relative;
        font-family: Lato, Sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.48px;
        color: #fff;
        padding-bottom: 16px;
        span {
          padding-left: 33px;
        }
        &::before {
          position: absolute;
          content: "";
          background-image: url("../../../../assests/images/dashborad/Circle_Check.svg");
          left: 0;
          height: 24px;
          width: 24px;
          background-repeat: no-repeat;
          background-size: 100%;
          top: 2px;
        }
      }
    }
  }
}
