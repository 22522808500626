@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@font-face {
  font-family: Pirulen;
  src: url("./assests/fonts/pirulen/pirulen\ rg.otf");
  font-weight: 400;
  font-style: normal;
}
body {
  background-color: #141518;
  /* padding: 26px 35px; */
}
.maxWidth .modal-dialog {
  max-width: 90%;
  height: auto;
}

.maxWidth .modal-content {
  padding: 30px;
  height: auto;
}
.table > :not(caption) > * > * {
  background: #1e1f25 !important;
}
.table-stripednew > tbody > tr:nth-of-type(odd) > * {
  background-color: #282c38 !important;
}

.loder {
  min-width: 33px;
  min-height: 33px;
  display: block !important;
  border-radius: 50%;
}

.table-responsive {
  /* max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto !important; */
  height: calc(100vh - 337px);
  overflow-y: auto;
  /* overflow-x: hidden; */
}
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255) !important;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}
.table-responsive::-webkit-scrollbar {
  width: 5px !important;
  height: 8px;
  background-color: #f5f5f5 !important;
}
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.72, #0aceec),
    color-stop(0.72, #0aceec),
    color-stop(0.72, #0aceec)
  ) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

/* Select the date input with the specified type */
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert the color to white */
}
