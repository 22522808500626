.header_menu {
  // position: fixed;
  // top: 27px;
  background: #1e1f25;
  width: 100%;
  // width: calc(100vw - 352px);
  // margin-left: 267px;
  border-radius: 20px;
  // height: 80px;
  padding: 20px 28px;

  .header_submenu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        color: #fff;
        display: inline-block;
        font-family: Lato, Sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        position: relative;

        .profile-name {
          position: relative;
          top: 10px;
          color: "white";
          font-size: 14;
          font-family: Lato, Sans-serif;
          font-weight: 400;
          word-wrap: break-word;
        }
      }

      .arrow-icon::before {
        position: absolute;
        content: ">";
        top: -13px;
        left: 13px;
        color: #757b8c;
      }
    }

    .right_menu {
      p {
        margin: 0;
        //font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      h3 {
        margin: 0;
        /* font-family: Inter; */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .dropdown-toggle {
        padding: 0;
        background: transparent;
        border: 0;
        margin-left: 30px;
        vertical-align: middle;

        &::after {
          display: none;
        }
      }
    }
  }
}

.emailHeader {
  text-transform: none;
  margin-top: 3px;
  color: #606880;
  font-size: 12;
  font-family: Lato, Sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.custom-dropdown-menu {
  background-color: black !important;
}

.custom-dropdown-item {
  color: white !important;
}

.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  background-color: black !important;
  color: white !important;
}
