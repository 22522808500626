.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.row-flex .col-sm-12,
.row-flex .col-sm-6 {
  margin-bottom: 20px;
}
.dmeArea {
  background: #23262f;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  height: 100%;

  h4 {
    color: #0aceec;
    font-size: 20px;
    font-family: Lato, Sans-serif;
    font-weight: 800 !important;
  }

  p {
    font-size: 17px;
    font-family: Lato, Sans-serif;
    font-weight: 300;
    margin: 0px;
    color: #fff;
    line-height: 30px;

    .code {
      color: #0aceec;
    }
  }

  li {
    font-size: 17px;
    font-family: Lato, Sans-serif;
    font-weight: 300;
    margin: 0px;
    line-height: 30px;
    color: #fff;
  }
}
