.confirmDelete {
    .modal-dialog {
        max-width: 100%;
        width: 480px;
        position: absolute;
        top: 47%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
    }

    .modal-body {
        border: 0;
        color: white;

        .info {
            display: flex;
            justify-content: center;
            font-size: 18px;
            border-bottom: 1px solid #282C38;
        }
    }

    .modal-header {
        border: 0;
        color: white;
        padding-bottom: 1px;

        .btn-close {
            color: #fff;
            background-image: url("../../assests/images/dashborad/close.png");
            background-size: 100%;
            opacity: 1;
            box-shadow: none;
        }
    }

    .modal-content {
        border-radius: 12px;
        background: #1e1f25;
    }

    .modal-footer {
        justify-content: center;
        border: 0px;
    }
}

.delete-yes-button {
    background: #0aceec !important;
    color: black !important;
    width: 70px;
    height: 40px;
}


.delete-no-button {
    color: #f93440 !important;;
    background: transparent !important;;
    border-color: #f93440 !important;
    width: 70px;
    height: 40px;
}